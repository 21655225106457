<template>
  <div class="main index" v-if="!isLoading">
    <!-- For title -->
    <div class="columns is-mobile is-multiline">
      <div class="column is-12">
        <h1 class="is-size-3"><b>Customize Level</b></h1>
      </div>
      <div class="column is-12">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <h3 class="is-size-6">Bank Soal</h3>
            </li>
            <li>
              <h3 class="is-size-6 breadcrumb-item">Customize Level</h3>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <!-- For table -->
    <div class="columns is-mobile">
      <div class="column is-12">
        <div class="card card-table">
          <LevelTable ref="tableLevel" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LevelTable from '@/components/V2/Level/LevelTable'

export default {
  name: 'index-level',
  components: {
    LevelTable,
  },
  data() {
    return {
      isLoading: false,
      isModalOpen: false,
      editData: null,
    }
  },
}
</script>
<style lang="scss">
.card {
  padding: 25px;
}
</style>

<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(addNewData)">
        <div class="form-wrapper" style="width: auto;margin-top: 15px;">
          <div class="flex-container">
            <div class="flex-row">
              <div class="key">
                <p class="input-label">
                  Name
                  <span class="field-required">*</span>
                </p>
              </div>
              <div class="value input-expanded">
                <ValidationProvider
                  name="name"
                  rules="required"
                  v-slot="{ errors }"
                  style="width: 100%;"
                >
                  <b-input
                    v-model="data.name"
                    expanded
                    placeholder="Level name"
                    class=" is-right"
                  ></b-input>
                  <p class="notif has-text-danger required">
                    {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </div>
            </div>

            <b-button
              native-type="submit"
              type="is-hcc"
              expanded
              style="font-weight: bold;"
            >
              {{ editData == null ? 'Add' : 'Update' }}
            </b-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    editData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      data: {
        id: null,
        name: null,
      },
    }
  },
  mounted() {
    if (this.editData != null) {
      this.data.id = this.editData.id
      this.data.name = this.editData.name
    }
  },
  methods: {
    ...mapActions({
      fetchLevel: 'newLevel/fetchLevel',
      createLevel: 'newLevel/createLevel',
      updateLevel: 'newLevel/updateLevel',
    }),
    async addNewData() {
      let data = {
        id: this.data.id,
        name: this.data.name,
      }
      this.isLoading = true
      try {
        if (this.editData == null) {
          await this.createLevel(data)
          this.success('Create Level Success')
        } else {
          await this.updateLevel(data)
          this.success('Update Level Success')
        }
        this.isLoading = false
        this.$emit('close-modal', { reload: true })
      } catch (err) {
        this.danger(err.response.data.message)
        this.isLoading = false
      }
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.input-expanded .control {
  width: 100%;
}

.flex-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
}

.flex-row {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: left;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.key p {
  text-align: left;
  min-width: 200px;
  font-weight: bold;
  color: #000000;
}

.value {
  display: flex;
  flex: 1;
}

.notif {
  text-align: left;
  font-size: 12px;
}
</style>

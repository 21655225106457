<template>
  <div>
    <div class="columns is-mobile  is-multiline">
      <div class="column is-10"></div>
      <div class="column is-2">
        <b-field grouped position="is-right">
          <b-button
            type="is-hcc"
            title="Create Level"
            @click="isModalOpen = true"
          >
            Add Level
          </b-button>
        </b-field>
      </div>
    </div>
    <div class="columns is-mobile is-multiline">
      <div
        class="column is-6 message"
        style="padding:0px;background:none;margin:0px;"
      >
        <div
          class="columns"
          style="background: #c7dff5;padding:1rem 1rem 1rem 0rem;margin:1rem 1rem 0rem 1rem;border-radius:6px;"
        >
          <div class="column is-one-fifth" style="text-align:right;">
            <svg
              style="width:80%;height:auto;color:#6964B7;"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
              />
            </svg>
          </div>
          <div class="column">
            <p class="title">
              <strong>
                Please rank the following shows from easiest to least hardest
                ones
              </strong>
            </p>
            <p class="description">
              Drag items from top to below or otherwise. The number of priority
              comes from number one which is the easiest one and followed by the
              rest priority number that you rank from.
            </p>
          </div>
        </div>
      </div>
      <div class="column is-6" style="margin:0rem;padding:1rem 1rem;">
        <draggable
          v-model="rank"
          class="border-ranking"
          @end="updateRank(rank)"
        >
          <div
            v-for="(ranking, indexRanking) in rank"
            :key="indexRanking"
            style="padding-bottom: 2%"
          >
            <div class="box-answer">
              <div class="columns is-mobile">
                <div class="column is-10" style="word-wrap: break-word">
                  {{ indexRanking + 1 }}. {{ ranking.name }}
                </div>
                <div
                  class="column is-1 edit-button"
                  style="margin: auto;opacity:0.4;"
                  @click="editLevel(ranking.id, ranking.name)"
                >
                  <b-icon
                    size="is-small"
                    icon="pen"
                    style="vertical-align: middle; color: #00a3a3 !important;cursor:pointer;"
                  ></b-icon>
                </div>
                <div
                  class="column is-1 edit-button"
                  style="margin: auto;opacity:0.4"
                  @click="deletePopup(ranking.id)"
                >
                  <b-icon
                    size="is-small"
                    icon="times"
                    style="vertical-align: middle; color: #00a3a3 !important;cursor:pointer;"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
    <!-- For Create -->
    <NewModal
      v-if="isModalOpen"
      :is-modal-open="isModalOpen"
      :title-modal="editData == null ? 'Add Level' : 'Edit Level'"
      :on-close-modal-callback="closeModal"
    >
      <template v-slot:content>
        <FormAddLevel v-on:close-modal="closeModal" :edit-data="editData" />
      </template>
    </NewModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import draggable from 'vuedraggable'
import FormAddLevel from '@/views/v2/level/FormAddLevel'
import NewModal from '@/components/V2/NewModal'

export default {
  components: {
    draggable,
    FormAddLevel,
    NewModal,
  },
  data() {
    return {
      rank: [],
      isModalOpen: false,
      editData: null,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    //for update level rank using draggable
    updateRank(array) {
      this.$store
        .dispatch('newLevel/updateRankLevels', array)
        .then()
        .catch(() => {
          this.danger('Gagal update ranking level')
          this.fetchData()
        })
    },
    ...mapActions({
      // fetchLevels: "newLevel/fetchLevels",
      deleteLevelData: 'newLevel/deleteLevel',
    }),

    // For delete popup
    deletePopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Delete Level',
        message: `Are you sure want to delete this level?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, delete it!',
        type: 'is-hcc',
        onConfirm: () => this.deleteLevel(id),
      })
    },

    // For delete level
    async deleteLevel(id) {
      try {
        await this.deleteLevelData(id)

        this.success('Delete Level Success')
      } catch (err) {
        this.danger(err)
      }

      this.fetchData()
    },

    // For fetching data
    async fetchData() {
      const response = await this.$store.dispatch('newLevel/fetchLevels')
      this.rank = response.data
    },

    editLevel(id, name) {
      this.editData = { id, name }
      this.isModalOpen = true
    },

    // For reload
    reload() {
      this.fetchData()
    },

    // For success alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
    closeModal(event) {
      if (event.reload != undefined) {
        this.reload()
      }
      this.isModalOpen = false
      this.editData = null
    },
  },

  // computed: {
  //   ...mapGetters({
  //     levels: "newLevel/getNewLevels"
  //   })
  // }
}
</script>
<style scoped>
.title {
  font-size: 18px;
  margin-top: 5px;
  color: #1d1d1d !important;
  font-family: 'Nunito Sans';
}
.description {
  font-size: 16px;
  color: #7e7e7e !important;
  font-family: 'Nunito Sans';
}
.modal-copy-link {
  width: 500px !important;
}
.box-answer {
  /* border: 1px solid black; */
  margin-bottom: 1%;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  color: #4a4a4a;
  padding-top: 0.5rem;
  padding-bottom: 0.25px;
  padding-left: 1rem;
  padding-right: 0.5rem;
  font-weight: bold;
  height: 42px;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  /* display: block; */
}
.box-answer:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.border-ranking {
  border: 1px solid #7e7e7e;
  border-radius: 6px;
  /* width: 45% !important;*/
  background-color: #f6f6f6;
  padding: 1rem 1rem;
}
.message {
  background-color: #c7dff5;
  margin: 2rem;
  border-radius: 6px;
  height: max-content;
  padding: 30px;
}
.edit-button:hover {
  cursor: pointer;
  opacity: 0.6 !important;
}
</style>
